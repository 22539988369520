@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Fira Code", monospace;
  background: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primaryColor: #0d263b;
  --secondaryColor: #696969;
  --activeColor: #0061df;

  --width: 1440px;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  /* line-height: 36px; */
  /* text-align: center; */
  letter-spacing: -0.02em;
  color: #0d263b;
}

.subTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #0d263b;
}

.description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 24px; */
  color: #696969;
}

.center {
  display: flex;
  justify-content: center;

}

.nocopy {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  
  height: 100% !important;
  display: flex;
  align-items:  center;
  
 
}


.ant-select.ant-select-single.ant-select-show-arrow{
  width: 200px;
  border: none;
}